<template>
    <div id="storepayment">
        <div style="text-align: center">
            <strong> Payment Summary </strong>
        </div>
        <div id="confirmationText" style="margin-left: 40px">
            <br /><b><u>Player Details:</u></b> <br />Player Name:
            <b> {{ this.playerName }}</b> <br />Player ID:
            <b>
            {{ this.playerId }}
            <br /><br /><u>Recharge Details:</u><br
          /></b> Game: {{ this.selectedGame.name }} <b>  </b><br /> Package : <b> {{ this.selectedPackage.name }} </b><br /> Price :<b>
            {{ this.selectedPackage.price.amount + " " + this.selectedPackage.price.currency }} (Including
            Taxes)</b
          >
    
          <br />
          Amount To Pay :<b>
            {{
             this.amountToPay() + 
              " " +
              this.selectedPackage.price.currency
            }}</b
          >
        </div>
        <div v-if="this.$props.isFromVoucher" class="giftCardOption">
          <input
            type="checkbox"
            id="checkbox"
            v-model="giftCardBalanceCheckedBox"
          />
          <label v-if="giftCardBalanceCheckedBox" for="checkbox"
            >Remaining giftcard balance will be
            {{
              this.voucherValidateResponse.balance.amount >
              this.$props.selectedPackageAmount
                ? this.voucherValidateResponse.balance.amount -
                  this.selectedPackageAmount
                : 0
            }}
            Credits</label
          >
          <label v-else for="checkbox"
            >Use your giftcard balance
            {{ this.voucherValidateResponse.balance.amount}}{{ this.voucherValidateResponse.balance.currency }} Credits</label
          >
        </div>
        <hr />
        <div v-if="this.selectedPayment == 'zain'">
            <PhoneInput 
                @sendOTP="sendOTP"
                @verifyOTP = "verifyOTP"
                :otpVisible="otpVisible"
            />
        </div>
       
        <!-- Buttons -->
        <div class="pkg-btn-grp">
          <v-btn color="grey" @click="goBack" id="back-btn" class="pkg-btn">
            Back
          </v-btn>
          <v-btn :disabled="payButtonDisabled" color="primary" @click="onBuyClick" id="pay-btn" class="pkg-btn" >
            {{
              "Pay "+ this.selectedPackage.price.symbol + this.amountToPay() 
            }}
          </v-btn>
        </div>
    
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
</template>

<script>
import * as SageUtils from "@/utils/SageUtils";
import * as StoreWebClient from "@/client/StoreWebClient";
import * as SageConstants from "../config/SageConstants";
import { TransactionType } from "../config/SageConstants";
import PhoneInput from "./PhoneNumberValidate.vue"
export default {
    name: "StorePayment",
    components:{
        PhoneInput
    },
    props: {
        isFromVoucher: {
            type: Boolean,
            deafult: false,
        },
        playerId: {
            type: String,
        },
        playerName: {
            type: String,
        },
        selectedPackage: {
            type: {},
        },
        selectedGame: {
            type: {},
        },
        voucherValidateResponse: {
            type: {},
            default: null
        },
    },
    data() {
        return {
            isLoading: false,
            playerPackageAmt: 0,
            priceInMfCredits: 0,
            razorpayKey: "",
            giftCardBalanceCheckedBox: this.$props.isFromVoucher,
            giftCardPin: "",
            payButtonText: "",
            paymentOption: [],
            selectedPayment: SageConstants.RAZORPAY,
            availablePaymentMethods: [],
            payAmount: 0,
            phoneNo : "",
            orderInfo : {},
            otpInfo: {},
            payButtonDisabled: false,
            otpVisible : false
        };
    },
    async created() {
        // console.log(JSON.stringify(this.selectedPackage))
        await this.init();
    },
    computed: {
        // payAmount : function(){

        // }
    },
    methods: {
        async sendOTP(phoneInfo){
            console.log("Phone Info", phoneInfo)
            this.phoneNo = phoneInfo.phoneNumber
            
            this.isLoading = true;
                // console.log(JSON.stringify(this.$props))
                const payload = {
                    gateway: this.selectedPayment,
                    gameId: this.$props.selectedGame.gameId,
                    targetPid: this.$props.playerId,
                    txnType: "grant",
                    packageId: this.$props.selectedPackage.id,
                    ...(this.selectedPayment == SageConstants.PAYMENT_OPTIONS.zain && {
                        data: {
                            msisdn : this.phoneNo
                        }
                    })
                };
                try{
                    const order = await StoreWebClient.createCheckout(payload);
                    this.orderInfo = order
                    this.otpVisible = true
                }
                catch(err){
                    this.showConnectionErrorDialog(err)
                }
                this.isLoading = false
        },
        verifyOTP(otpInfo){
            console.log("OTP Info", otpInfo)
            if(otpInfo.otp.length == 5){
                this.payButtonDisabled = false
            }
            this.otpInfo = otpInfo
        },
        amountToPay() {
            let payAmount = this.selectedPackage.price.amount
            if (this.voucherValidateResponse) {
                if (this.giftCardBalanceCheckedBox) {
                    payAmount = this.selectedPackage.price.amount - this.voucherValidateResponse.balance.amount
                    payAmount = payAmount <= 0 ? 0 : payAmount
                } else {
                    payAmount = this.selectedPackage.price.amount
                }
            }
            this.payAmount = payAmount
            return payAmount
        },
        async init() {
            let res = await StoreWebClient.getAvailablePaymentOption()
            // this.availablePaymentMethods = res.list
            //   this.paymentOption = this.getPaymentOptionsHtml(res.gateway);
            this.selectedPayment = res.gateway;
            if(this.selectedPayment == SageConstants.PAYMENT_OPTIONS.zain){
                this.payButtonDisabled = true
            }
            if (this.voucherValidateResponse && this.voucherValidateResponse.balance.currency != this.selectedPackage.price.currency) {
                const that = this
                this.$swal.fire({
                    icon: "error",
                    title: "This voucher is not valid in your country",
                    confirmButtonText: "OK",
                    willClose: () => {
                        // SageUtils.printMessage();
                        that.$router.go();
                    },
                });
            }

        },
        async createGiftCardCheckout() {
            //   console.log(
            //     "voucherValidateResponse " +
            //       JSON.stringify(this.voucherValidateResponse)
            //   );
            this.isLoading = true;
            let payload = {
                gameId: this.selectedGame.gameId,
                targetPid: this.playerId,
                currency: this.voucherValidateResponse.balance.currency,
                checkoutType: "grant",
                giftCardInfo: {
                    voucherSource: this.voucherValidateResponse.giftCardInfo.voucherSource,
                    voucherCode: this.voucherValidateResponse.giftCardInfo.voucherCode,
                    pin: this.voucherValidateResponse.giftCardInfo.pin,
                },
                packageId: this.selectedPackage.id,
            };
            const that = this;
            try {
                const res = await StoreWebClient.createGiftCardCheckout(payload);
                this.isLoading = false;

                if (res.checkoutInfo) {
                    await this.showRazorpayPopup(res.checkoutInfo);
                } else {
                    this.$swal.fire({
                        icon: SageUtils.getIconFromResponse(res.status),
                        title: SageUtils.getTitleFromResponse(res.status),
                        html: SageUtils.getSuccessTextFromRedeemResponse(
                            res,
                            this.playerId
                        ),
                        confirmButtonText: "OK",
                        willClose: () => {
                            // SageUtils.printMessage();
                            that.$router.go();
                        },
                    });
                }
            } catch (err) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "info",
                    title: "Package Grant Failed ",
                    text: err ? err : "Couldn't process transaction",
                    showConfirmButton: true,
                    willClose: () => {
                        // SageUtils.printMessage();
                        that.$router.go();
                    },
                });
            }
        },
        async onBuyClick() {
            try {
                if (this.$props.isFromVoucher && this.giftCardBalanceCheckedBox) {
                    await this.createGiftCardCheckout();
                } else {
                    await this.CreateFullCheckout();
                }
            } catch (err) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    title: "Transaction Failed ",
                    text: err ? err : "Couldn't process transaction",
                    showConfirmButton: true,
                });
            }
        },

        loadRazorpay() {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://checkout.razorpay.com/v1/checkout.js";
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        },
        async showRazorpayPopup(order) {
            // console.log("Show razor pay " + JSON.stringify(order))
            try {
                this.isLoading = true;

                const that = this;
                const payOptions = {
                    key: order.paymentData.clientData.key,
                    amount: order.paymentData.orderAmount * 100,
                    currency: order.paymentData.currency,
                    name: "Moonfrog Labs Pvt Ltd",
                    description: that.selectedPackage.name,
                    order_id: order.paymentData.clientData.id,
                    // if instead of handler, you want to use callback below is an example.
                    //callback_url: "http://localhost:8080/razorpay", //write the reseller dashboard link here
                    handler: async function(response) {
                        let timerInterval;
                        that.$swal.fire({
                            icon: "info",
                            title: "Payment is in progress",
                            text: "The page will reload in 10 seconds.",
                            timer: 10000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        });
                        that.isLoading = true;
                        setTimeout(async () => {
                            try {
                                const response = await StoreWebClient.checkPaymentStatus(
                                    order.paymentData.mfTxnId
                                );
                                const paymentStatus = response.paymentStatus;
                                that.isLoading = false;
                                that.$swal.fire({
                                    icon: SageUtils.getIconFromResponse(response.paymentStatus),
                                    title: SageUtils.getTitleFromResponse(response.paymentStatus),
                                    text: "Invoice sent to your email",
                                    showConfirmButton: true,
                                    html: SageUtils.getSuccessTextFromPaymentResponse(response),
                                    confirmButtonText: "OK",
                                    willClose: () => {
                                        that.$router.go();
                                    },
                                });
                            } catch (err) {
                                console.log(
                                    "Error while Check Payment Status: " + JSON.stringify(err)
                                );
                            }
                        }, 10000);
                    },
                    prefill: {
                        name: "",
                        email: "",
                    },
                    theme: {
                        color: "#80C767",
                    },
                    notes: {
                        isSage: "true",
                        packAmount: this.packAmount,
                        gst: this.taxRate,
                        txnType: TransactionType.PackageCredit,
                    },
                };

                this.isLoading = false;
                const rzp = new window.Razorpay(payOptions);
                rzp.open();
            } catch (e) {
                this.isLoading = false;
                this.showConnectionErrorDialog(e);
            }
        },

        async CreateFullCheckout() {
            try {
                this.isLoading = true;                

                if (this.selectedPayment == SageConstants.PAYMENT_OPTIONS.razorpay) {
                    const payload = {
                        gateway: this.selectedPayment,
                        gameId: this.$props.selectedGame.gameId,
                        targetPid: this.$props.playerId,
                        txnType: "grant",
                        packageId: this.$props.selectedPackage.id,
                        ...(this.selectedPayment == SageConstants.PAYMENT_OPTIONS.zain && {
                            data: {
                                msisdn : this.phoneNo
                            }
                    })
                    };
                    const order = await StoreWebClient.createCheckout(payload);
                    this.isLoading = false
                    this.orderInfo = order
                    const res = await this.loadRazorpay();

                    if (!res) {
                        this.showConnectionErrorDialog(
                            "Razorpay SDK failed to load. Check your internet connection."
                        );
                        return;
                    }
                    
                    await this.showRazorpayPopup(order);
                } else if (this.selectedPayment == SageConstants.PAYMENT_OPTIONS.zain) {
                    const verifyOTPPayload = {
                        "msisdn" : this.otpInfo.phoneNumber,
                        "mfTxnId" :this.orderInfo.paymentData.mfTxnId,
                        "pin" : this.otpInfo.otp,
                        "packageId" : this.orderInfo.packageId,
                        "price" : {
                            "amount" : this.orderInfo.paymentData.orderAmount,
                            "currency" : this.orderInfo.paymentData.currency
                        }

                    }
                    console.log("Verify otp payload ",verifyOTPPayload)
                    const response = await StoreWebClient.verifyOTP(verifyOTPPayload) 
                    this.isLoading = false;
                                this.$swal.fire({
                                    icon: SageUtils.getIconFromResponse(response.paymentStatus),
                                    title: SageUtils.getTitleFromResponse(response.paymentStatus),
                                    text: "Invoice sent to your email",
                                    showConfirmButton: true,
                                    html: SageUtils.getSuccessTextFromPaymentResponse(response),
                                    confirmButtonText: "OK",
                                    willClose: () => {
                                        this.$router.go();
                                    },
                                });
                }
            } catch (e) {
                this.isLoading = false;
                this.showConnectionErrorDialog(e);
            }
        },

        async showAamarpayPopUp() {
            const packAmount = parseFloat(this.$props.selectedPackage.price.amount);

            try {
                this.isLoading = true;
                const amount = SageUtils.getAmountInclusiveTaxes(
                    packAmount,
                    this.selectedCountryCode,
                    this.creditExchangeRate
                );
                let paymentURL = await StoreWebClient.createAamarpayOrder(
                    this.$store.state.account.email,
                    amount,
                    packAmount
                );
                if (paymentURL) window.open(paymentURL, "_self");
                else
                    this.showConnectionErrorDialog(
                        "Could not redirect to the payment page. Please try again"
                    );
            } catch (err) {
                this.showConnectionErrorDialog(err);
            } finally {
                this.isLoading = false;
            }
        },
        goBack() {
            this.$emit("onBack");
        },
        async showAamarpayStatus(query) {
            if (query.success === "true") {
                this.$swal
                    .fire({
                        icon: "success",
                        title: "Payment is Successful",
                        showConfirmButton: true,
                    })
                    .then(() => {
                        this.$router.push("/account");
                    });
            } else {
                this.$swal
                    .fire({
                        icon: "error",
                        title: "Payment failed!",
                        text: query.message,
                        showConfirmButton: true,
                    })
                    .then(() => {
                        this.$router.push("/account");
                    });
            }
        },

        showConnectionErrorDialog(error) {
            this.$swal.fire({
                icon: "error",
                text: error,
            });
        },
        getPaymentOptionsHtml(options) {
            let radioGroupOptions = [];
            for (const method of options) {
                //Razorpay
                // console.log(" MEthod " + method);
                let html = "";
                switch (method) {
                    case SageConstants.PAYMENT_OPTIONS.razorpay:
                        html += `<img src="https://drive.google.com/uc?export=view&id=1d3tgDrll2a_O4Ud89426X4usdWHVs54i" height="60px" width="80px"/>`;
                        // razorayHtml += "<b>Razorpay</b>"
                        break;
                    case SageConstants.PAYMENT_OPTIONS.zain:
                        //Zain
                        html += `<img src="https://drive.google.com/uc?export=view&id=12z9ZjE_UFT_EJoMSRxKKBJwKfnSsgR-H" height="50px" width="60px"/>`;
                        break;
                        // zainHtml += "<b>Zain</b>"
                }
                if (html) {
                    radioGroupOptions.push({ html: html, value: method });
                }
            }
            return radioGroupOptions;
        },
    },
};
</script>

<style scoped>
.giftCardOption {
    margin-top: 3%;
    padding-left: 8%;
    font-size: medium;
    font-style: italic;
}

.pkg-btn-grp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
}

@media (max-width: 767px) {
  .pkg-btn-grp {
    flex-direction: column-reverse;
  }
}

.pkg-btn {
    padding: 1rem 1rem;
    margin: 1rem;
    display: inline-block;
    vertical-align: middle;
    width: auto;
}

.paymentSelectHeader {
    font: bold;
    font-size: large;
    text-align: center;
}

.paymentOption {
    font: italic;
    font-size: medium;
}
</style>