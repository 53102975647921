<template>
    <div v-if="render == 0">
        <Error404/>
    </div>
    <div v-else :style="render ? 'display: block' : 'display: none'">
    
        <StorePageHeader :walletCredits="voucherInfo ? voucherInfo.balance : null" :giftCard="voucherInfo ? voucherInfo.giftCardInfo.voucherCode : null"></StorePageHeader>
        <!-- <div><button class="buyButton" @click="callFastSpringCheckout()">Pay Now</button></div> -->
        <div class="bg bg-image">
            <v-container class="gameContainer" style="margin: auto; padding-bottom: 50px">
                <v-container class="d-flex flex-row justify-content-center" full-height>
    
                    <div class="games">
                        <ul>
                            <li class="game" v-for="(game) in gamesArray" :class="{ active: game == selectedGame }" :key="game.gameId" :value="game" :id="game.gameId">
                                <b></b>
                                <b></b>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                                <button @click="gameSelected(game)" v-bind="attrs" v-on="on">
                                                  <span class="icon">
                                                    <img :src="game.icon" class="image" />
                                                  </span>
                                                  <!-- <span class="title">{{ game.name }}</span> -->
                                                </button>
                                    </template>
                  <span>{{ game.name }}</span></v-tooltip
                >
              </li>
            </ul>
          </div>

          <!-- Enter Details Area -->
          <div class="details">
            <!-- <div v-if="this.voucherInfo" style="right: 0">Balance : {{this.voucherInfo.balance}} {{this.voucherInfo.currency}}</div> -->
            <!-- Game Title -->
            <div class="gameTitle">
              {{ selectedGame.name }}
            </div>
            
            
            <div v-if="isJustLoaded">
              <div class ="ma-md-3" >
            </div>
            <!-- Enter PID -->
            <PlayerID @onclick="submitPID" :immediateRender = "true"/>
            </div>
            <!-- Select Package -->
            <div v-else-if="isPidJustVerified">
            <SelectPackage
                @onSubmit="grantStorePackage"
                @onBack="backToPidForm"
                :playerName="playerName"
                :playerId="playerId"
                :packagesArray="packagesArray"
                :immediateRender="true"
            />
          </div>
            <!-- Payment for Store Page -->
          <div v-else-if="isPackageJustSelected">
            <StorePayment
                @onBack = "backToSelectPackage"
                :isFromVoucher="this.$props.voucherInfo ? true : false"
                :playerName="playerName"
                :playerId="playerId"
                :selectedPackage="selectedPackage"
                :selectedGame="selectedGame"
                :voucherValidateResponse="this.$props.voucherInfo"
            />
          </div>
        </div>
        </v-container>
      </v-container>

      <footer style="text-align: center; color: #6c757d !important">
        <p>
          By using the website, you are agreeing to Moonfrog Labs
          <router-link
              class="footer-link"
              :to="{ name: 'redemptionTnC' }"
              target="_blank"
          >
            Terms &amp; Conditions
          </router-link>
        </p>
        <p class="font-italic" style="color: yellowgreen">
              In case of queries, contact: customer.service@moonfroglabs.com
        </p>
      </footer>

      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import PlayerID from "@/components/PlayerID";
import SelectPackage from "@/components/SelectPackage";
import * as client from "@/client/StoreWebClient";
import * as PaymentClient from "@/client/PaymentClient";
import StorePayment from "@/components/StorePayment";
import StorePageHeader from "../components/StorePageHeader";
import * as SageUtils from "@/utils/SageUtils";
import * as SageConstants from "@/config/SageConstants";
import Error404 from "../views/static/Error404.vue";
import axios from 'axios';
export default {
    name: "StorePage",
    components: {
        StorePayment,
        PlayerID,
        SelectPackage,
        StorePageHeader,
        Error404
    },

    props: {
        voucherInfo: {
            type: Object,
            default : null
        }
    },

    data() {
        return {
            credits: 0,
            userName: "User", //reseller name
            userCountryCode: "",
            currency: "INR",
            isLoading: true,
            gamesArray: [],
            selectedGame: {},
            playerId: "",
            playerName: "",
            packagesArray: [],
            selectedPackage: {},
            selectedPackageAmount: 0,
            selectedUserCountry: "IN",
            transactionsArr: [],
            transactionsLoaded: false,
            isPidJustVerified: false,
            isPackageJustSelected: false,
            isPaymentDone: false,
            isJustLoaded: true,
            page: 1,
            totalPages: 0,
            render: -1
        };
    },

    created() {
        // if (this.fromSignUp) this.$router.go();
        this.init();
        // console.log(" Store Page Home Props :" + JSON.stringify(this.$props))
    },

    methods: {
        // async callFastSpringCheckout() {
        //     const payload = {
        //         gateway: "fastspring",
        //         gameId: 125,
        //         targetPid: "ftwc927",
        //         txnType: "grant",
        //         packageId: "cash_120_24_rs50",
        //         store : "ludoclub"
        //     };
        //     const res = await axios.post(process.env.VUE_APP_URL + "/v1/checkout/payment", payload)
        //     // console.log("payment: " + JSON.stringify(res.data))

        //     // console.log("product: " + res.data.result.paymentData.clientData.productId)
        //     // fastspring.builder.add(res.data.result.paymentData.clientData.productId);
        //     // fastspring.builder.tag({'mfTxnId': res.data.result.paymentData.mfTxnId});
        //     // fastspring.builder.checkout();
        // },
        async init() {

            try {
                let res = await client.getStoreConfig()
                this.render = res.config.store.display
                this.userCountryCode = res.country
                if(!this.voucherInfo){
                this.gamesArray = await client.getGames();
                }
                else{
                    this.gamesArray = await client.getGames("voucher");
                }
                // this.gameSelected(this.gamesArray[0]);
                // console.log(this.$route.params)
                let gameSelected = false
                if (this.$route.params.game) {
                    this.gamesArray.forEach(game => {
                        if (game.key == this.$route.params.game) {
                            // console.log("Game" + JSON.stringify(game))
                            gameSelected = true
                            this.gameSelected(game)
                        }
                    })
                    if(!gameSelected){
                        this.gameSelected(this.gamesArray[0]);
                    }
                }
                else{
                    this.gameSelected(this.gamesArray[0]);
                }
                // setting vue cache
                this.$store.commit("setCountryCode", this.selectedUserCountry);
                if (!this.$store.state.creditExchangeRate[this.selectedUserCountry]) {
                    const creditExchangeRate =
                        await PaymentClient.getCreditExchangeRate();
                    this.$store.commit("setCreditExchangeRate", creditExchangeRate);
                }

            } catch (e) {
                this.showConnectionErrorDialog(e);
            } finally {
                this.isLoading = false;
            }
        },
        gameSelected(game) {

            this.playerId = "";
            this.playerName = "";
            this.packagesArray = [];
            this.selectedPackage = {};
            this.isPidJustVerified = this.isPackageJustSelected = false
            this.isJustLoaded = true
            this.selectedGame = game;
            // console.log("Voucher Info " + JSON.stringify(this.voucherInfo))
            if(!this.voucherInfo){
            this.$router.push({path : "/store" + "/" + this.selectedGame.key,append:true}).catch(()=>{})
            }
            // console.log("Game selected: " + JSON.stringify(this.selectedGame));
            let list = document.querySelectorAll(".game");
            for (let i of list) {
                i.className =
                    i.getAttribute("id") == game.gameId ? "game active" : "game";
            }
        },

        OnCountrySelected(event) {
            this.selectedUserCountry = event.target.value;
            this.currency = SageConstants.currencyCountry[this.selectedUserCountry]
        },

        async submitPID(playerId) {
            this.playerId = playerId;
            this.isLoading = true;
            try {
                //   apiResponse: {playerName: string, isValid: boolean, packages: []}
                let apiResponse = await client.getGamePackages(
                    this.selectedGame.gameId,
                    playerId
                );
                if (!apiResponse.isValid) throw Error("Player ID is not valid.");
                this.playerName = apiResponse.playerName;
                this.packagesArray = apiResponse.packages;
                for (const element of this.packagesArray) {
                    element.name +=
                        "(" +
                        element.price.currency +
                        " " + element.price.amount + ")" ;
                }
                this.isPidJustVerified = true
                this.isJustLoaded = false
                // document.getElementById("pid").style.display = "none";
                // document.getElementById("userCountryCode").style.display = "none";
                // document.getElementById("packages").style.display = "block";
            } catch (e) {
                this.showConnectionErrorDialog(e);
            } finally {
                this.isLoading = false;
            }
        },

        backToPidForm() {
            this.gameSelected(this.selectedGame);
            this.isJustLoaded = true
            this.isPidJustVerified = false
        },
        backToSelectPackage() {
            this.isPidJustVerified = true
        },
        grantStorePackage(pkgObj) {
            // console.log("grantStorePackage: " + JSON.stringify(pkgObj))
            this.selectedPackage = pkgObj.pkg;
            this.selectedPackageAmount = pkgObj.pkg.priceInMfCredits;
            this.isPackageJustSelected = true
            this.isPidJustVerified = false
        },

        showConnectionErrorDialog(error) {
            this.$swal.fire({
                icon: "error",
                text: error,
                confirmButtonText: "OK",
            });
        },

    },
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

.bg-image {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

body {
    min-height: 100vh;
    background: #fff;
}

.gameContainer {
    margin: auto;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.details {
    position: relative;
    top: 20px;
    bottom: 20px;
    /* left: 90px; */
    /* right: 20px; */
    width: 500px;
    border-radius: 10px;
    box-sizing: initial;
    background: #fff;
    transition: width 0.5s;
    overflow-x: hidden;
    /* margin: auto; */
}

.gameTitle {
    margin: 10%;
    font-size: 30px;
    font-weight: 600;
    color: #007bff;
}

.games {
    position: relative;
    top: 20px;
    /* left: 20px; */
    /* right: 520px; */
    bottom: 20px;
    height: 400px;
    width: 70px;
    border-radius: 10px;
    box-sizing: initial;
    border-left: 5px;
    /* border-left: 5px solid #4d5bf9; */
    /* background: #4d5bf9; */
    transition: width 0.5s;
    overflow-x: auto;
    /* margin: auto; */
}

.games.active {
    width: 300px;
}

.games ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 5px;
    padding-top: 40px;
}

.games ul li {
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 20px;
}

.games ul li.active {
    background: #fff;
}

.games ul li b:nth-child(1) {
    position: absolute;
    top: -20px;
    height: 20px;
    width: 100%;
    background: #fff;
    display: none;
}

.games ul li b:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    /* background: #4d5bf9; */
    background: rgba(25, 22, 72, 0.77);
}

.games ul li b:nth-child(2) {
    position: absolute;
    bottom: -20px;
    height: 20px;
    width: 100%;
    background: #fff;
    display: none;
}

.games ul li b:nth-child(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    /* background: #4d5bf9; */
    background: rgba(26, 23, 77, 0.77);
}

.games ul li.active b:nth-child(1),
.games ul li.active b:nth-child(2) {
    display: block;
}

.games ul li button {
    position: relative;
    display: block;
    width: inherit;
    display: flex;
    text-decoration: none;
    color: #fff;
}

.games ul li.active button {
    color: #333;
}

.games ul li button .icon {
    position: relative;
    display: block;
    width: inherit;
    height: 60px;
    line-height: 70px;
    text-align: center;
    margin: auto;
}

.games ul li button .icon .image {
    font-size: 1.5em;
    max-width: 45px;
    transform: translateY(-13%);
}

@media (max-width: 510px) {
    .games ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-left: 0px;
        padding-top: 40px;
    }
    .games ul li button .icon .image {
        font-size: 1.5em;
        max-width: 35px;
        transform: translateY(-13%);
    }
}

.games ul li button .title {
    position: relative;
    display: block;
    padding-left: 10px;
    height: 60px;
    line-height: 60px;
    white-space: normal;
}

p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    text-align: center;
    color: white;
}

.footer-link {
    text-align: center;
    font-weight: bold;
    color: #007bff;
}

.footer-link:hover {
    text-decoration: underline;
}
</style>

