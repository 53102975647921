<template>
  <div class="container">
    <div class="phone-input" v-if="!otpVisible">
    <h5>Enter Your Phone Number</h5>

        <VuePhoneNumberInput v-model="phoneNumber" 
        v-bind="phone.props"
        
        />
      <div class="text-center">
        <v-btn color="primary" @click="sendOTP" id="pay-btn" class="otp-btn">
          Send OTP
        </v-btn>
      </div>
    </div>

    <div class="otp-input" v-if="otpVisible">
      <h5>Enter the OTP</h5>
      <v-otp-input v-model="otp" type="number" length="5" @finish="verifyOTP"></v-otp-input>
    </div>
  </div>
</template>
  
<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components:{
    VuePhoneNumberInput
  },
  props:{
    otpVisible: {
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      phone :{
        props:{
          onlyCountries: ["IQ"],
          defaultCountryCode:"IQ",
          noExample : true
        }
      },
      isMobile: false,
      phoneNumber: "",
      otp: "",
      phoneNumberRules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    };
  },
  mounted(){
    console.log(navigator.userAgent)
    this.isMobile = window.innerWidth < 768;
    // this.isMobile = "/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i".test(navigator.userAgent);
  },
  methods: {
    sendOTP() {
      // TODO: send OTP to the provided phone number
      console.log(`Sending OTP to ${this.phoneNumber}`);
      this.otpVisible = true; // show the OTP input
      let formattedNumber = "964" + this.phoneNumber.replace(/\D/g, '');
      this.$emit("sendOTP", {
                phoneNumber:formattedNumber,
            });
    },
    verifyOTP() {
      // TODO: verify the entered OTP
      let formattedNumber = "964" + this.phoneNumber.replace(/\D/g, '');
      this.$emit("verifyOTP", {
                phoneNumber:formattedNumber,
                otp: this.otp
            });
      console.log(`Verifying OTP: ${this.otp}`);
    },
  },
};
</script>
  
  <style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (min-width: 100px) {
  .container {
    flex-direction: column;
  }
}
.phone-input,
.otp-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 10px;
}

input[type="number"],
input[type="text"] {
  width: 100%;
  padding: 1rem 1rem;
  font-size: medium;
  border: none;
  border-bottom: 2px solid #ccc;
}

button {
  padding: 10px 20px;
  font-size: medium;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
}

.no-uppercase {
}
.otp-btn {
    padding: 1rem 1rem;
    margin: 1rem;
    display: inline-block;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* width: 30%; */
    text-transform: unset !important;
}

button:hover {
  /* background-color: #0069d9; */
}
.error {
  border: 2px solid red;
}
</style>
